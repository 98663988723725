<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page_header_new";
import appConfig from "@/app.config";

import {AgGridVue} from 'ag-grid-vue'
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

import sendBlockBtn from "@/views/pages/maclTalk/sendBlockBtn";
import editBtn from "@/views/pages/kakaoTalk/editMemberBtnKaTalkOnly"

import axios from "axios";
import {mapState} from "vuex";
import {loggedInfoObject} from "@/state/helpers";
import {msgConfirmOptCenter, msgModalOptCenter} from "@/components/globalFunctions";

export default {
  components: {
    Layout,
    PageHeader,
    AgGridVue,
    /*sendBlock: sendBlockBtn*/
  },
  page: {
    title: "수신자 관리",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      title: "수신자 관리",
      items: '알림톡 수신자 목록을 관리합니다.',
      gridApi: null,
      columnApi: null,
      columnDefs: [
        {headerName: '고객명', field: 'name'},
        {headerName: '연락처', field: 'phone'},
        {headerName: '수신상태', field: 'state', maxWidth: 90},
        {headerName: '수신관리', field: 'name', cellRendererFramework: sendBlockBtn, maxWidth: 98},
        {headerName: '등록일시', field: 'reg_date'},
        {headerName: '관리', field: '', cellRendererFramework: editBtn, maxWidth: 160},
        //{headerName: '삭제', field: '', cellRendererFramework: delBtn, maxWidth: 86}
      ],
      talkList: [],
      context: {},
      newMember: {
        'name': '',
        'phone': '',
      },
      excelFile: '',
      today: new Date(),
    };
  },
  computed: {
    ...mapState(loggedInfoObject),
  },
  beforeMount() {
    //ag-grid 부모 자식간 통신을 위해 필요함
    this.context = {
      componentParent: this
    }
  },
  mounted() {
    this.callMemberList()
  },
  methods: {
    //Ag-grid
    onFirstDataRendered() {
      //event.api.sizeColumnsToFit()
      //event.api.autoSizeColumns(true)
      //console.log(event)
      this.columnApi.autoSizeAllColumns(false)
    },
    onFilterTextBoxChanged() {
      this.gridApi.setQuickFilter(this.$refs.default_input.value)
    },
    onGridReady(params) {
      this.gridApi = params.api
      this.columnApi = params.columnApi
    },

    //수신자 리스트 가져오기
    callMemberList() {
      let url = `/api/kakao/custom/${this.logged_info.mart_srl}`
      axios.get(url).then((response) => {
        //console.log(response.data[0])

        this.talkList = response.data
      }).catch(err => console.log(err))
    },

    //고객 전화번호 추가
    addPhoneNum() {
      let url = `/api/kakao/custom/${this.logged_info.mart_srl}`

      //console.log('newMember', this.newMember)

      if (this.newMember.name == '') {
        this.$bvModal.msgBoxOk('등록하실 수신자 성명을 입력해주세요.', msgModalOptCenter)
      } else if (this.newMember.phone == '') {
        this.$bvModal.msgBoxOk('등록하실 수신자 연락처를 입력해주세요.', msgModalOptCenter)
      } else {
        axios.post(url, this.newMember).then((res) => {
          //console.log(res.data)

          if (res.data.status == 'fail') {
            this.$bvModal.msgBoxOk('등록에 실패하였습니다.', msgModalOptCenter)
          } else {
            this.$bvModal.msgBoxOk('등록되었습니다.', msgModalOptCenter)

            this.newMember.name = ''
            this.newMember.phone = ''

            this.callMemberList()
          }
        }).catch(err => console.log(err))
      }
    },

    //마트내 수신자 전체 삭제
    delAllMember() {
      let url = `/api/kakao/customs/${this.logged_info.mart_srl}`

      this.$bvModal.msgBoxConfirm('※ 주의\n\n등록되어 있는 수신자를 모두 삭제하시겠습니까?', msgConfirmOptCenter)
          .then((sel) => {
            if (sel) {
              axios.delete(url)
                  .then((res) => {
                    console.log(res.data)

                    this.callMemberList()
                  })
                  .catch(err => console.log(err))
            }
          })
    },

    //upload 엑셀
    uploadExcel() {
      console.log('uploadExcel')

      let url = `/api/kakao/custom/file/excel/${this.logged_info.mart_srl}`

      let fd = new FormData()

      fd.append('excel', this.excelFile)

      if (this.excelFile != '') {
        axios.post(url, fd).then((res) => {
          console.log(res.data)
          if (res.data.status == 'fail') {
            this.$bvModal.msgBoxOk(`${res.data.msg}`, msgModalOptCenter)
          } else {
            this.$bvModal.msgBoxOk('성공적으로 등록되었습니다.', msgModalOptCenter)
            this.callMemberList()
          }
        }).catch(err => console.log(err))
      } else {
        this.$bvModal.msgBoxOk('파일이 존재하지 않습니다.\n파일을 선택해주세요.', msgModalOptCenter)
      }
    },

    //엑셀 파일 선택
    selectExcel() {
      let input = event.target;
      console.log(input.files[0])
      this.excelFile = input.files[0]
    },

    //엑셀 다운로드
    downExcel() {
      console.log('downExcel', this.talkList[0])

      let XLSX = require('xlsx')
      let tmpList = []

      let tmpObject = {
        '고객명': '',
        '연락처': '',
        '전체주소': '',
        '수신상태': '',
        '등록일시': ''
      }

      for (let i = 0; i < this.talkList.length; i++) {

        tmpObject = {
          '고객명': '',
          '연락처': '',
          '전체주소': '',
          '수신상태': '',
          '등록일시': ''
        }

        tmpObject['고객명'] = this.talkList[i].name
        tmpObject['연락처'] = this.talkList[i].phone
        tmpObject['수신상태'] = this.talkList[i].state

        if (this.talkList[i].addr == '' && this.talkList[i].addr_detail == '') {
          tmpObject['전체주소'] = ''
        } else if (this.talkList[i].addr != '' && this.talkList[i].addr_detail == '') {
          tmpObject['전체주소'] = this.talkList[i].addr
        } else {
          tmpObject['전체주소'] = this.talkList[i].addr + ' ' + this.talkList[i].addr_detail
        }

        tmpObject['등록일시'] = this.talkList[i].reg_date

        tmpList.push(tmpObject)
      }

      const workBook = XLSX.utils.book_new()
      const workSheet = XLSX.utils.json_to_sheet(tmpList)
      XLSX.utils.book_append_sheet(workBook, workSheet, `수신자목록_${this.toStringByFormatting(this.today)}`)
      XLSX.writeFile(workBook, `수신자목록_${this.toStringByFormatting(this.today)}.xlsx`)
    },

    toStringByFormatting(source) {
      //console.log(new Date().toJSON())
      const year = source.getFullYear();
      const month = this.leftPad(source.getMonth() + 1);
      const day = this.leftPad(source.getDate());

      let time = year + month + day

      return time
    },

    //날짜 formating
    leftPad(value) {
      if (value >= 10) {
        return value;
      }
      return `0${value}`
    },

  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items"/>
    <div class="row">
      <div class="col-xl-3 p-0">
        <div class="card">
          <div class="card-body m-0">
            <h5>단일 수신자 추가</h5>
            <hr class="my-3"/>
            <div class="mb-2">
              <label class="form-label">고객명</label>
              <input class="form-control" v-model="newMember.name"/>
            </div>
            <div class="mb-2">
              <label class="form-label">전화번호</label>
              <input class="form-control" v-model="newMember.phone"
                     onkeyup="this.value=this.value.replace(/[^0-9]/g,'')"/>
            </div>
            <b-button block
                      variant="primary"
                      style="width: 100%"
                      @click="addPhoneNum"
            >
              등록
            </b-button>
          </div>
        </div>

        <div class="card">
          <div class="card-body m-0">
            <div class="row">
              <div class="col-lg-10">
                <h5>엑셀 파일 수신자 추가</h5>
              </div>
            </div>

            <hr class="my-3"/>
            <div class="mb-3">
              <div style="display: flex; justify-content: space-between">
                <div>
                  <label class="form-label">수신자 엑셀 파일</label>
                </div>
                <div>
                  <button type="button"
                          class="btn btn-soft-light"
                          style="padding: 0 4px;"
                          @click="$bvModal.show('info')"
                  >
                    <i class="uil uil-question-circle"></i>
                  </button>
                  <b-modal
                      id="info"
                      ok-only
                      ok-title="닫기"
                      title="도움말"
                  >
                    <p style="margin: 0 0 16px 0;">
                      수신자 목록이 들어있는 엑셀파일을 선택해주세요.
                    </p>
                    <center>
                      <img style="border:2px solid blue; margin-bottom: 16px;"
                           :src="require('@/assets/mcimages/excel_example.png')"
                      >
                    </center>
                    <p style="margin: 0; color: #dd4b39;">
                      ※ 위 이미지처럼 엑셀의 1번째줄은 제목줄로 해주시고,
                      <br>이름이 있는 부분의 제목은 '고객명',
                      <br>연락처가 있는 부분의 제목은 '전화번호'로 기입해주시면,
                      <br>해당 데이터가 어떤 열에 있든지 찾아서 입력해줍니다.
                    </p>
                  </b-modal>
                </div>
              </div>
              <input class="form-control" type="file" id="formFile" accept=".xls,.xlsx" @change="selectExcel"/>
            </div>
            <b-button
                block
                variant="primary"
                style="width: 100%"
                @click="uploadExcel"
            >
              등록
            </b-button>
          </div>
        </div>
      </div>
      <div class="col-xl-9">
        <div class="card">
          <div class="card-header">
            <div class="row">
              <div class="col-8"></div>
              <div class="col-4">
                <input ref="default_input"
                       class="form-control"
                       placeholder="검색어를 입력하세요"
                       @input="onFilterTextBoxChanged()"
                />
              </div>
            </div>
          </div>
          <div class="card-body">
            <ag-grid-vue
                style="height: calc(100vh - 420px); width: 100%; text-align: center;"
                class="ag-theme-alpine"
                :columnDefs="columnDefs"
                :rowData="talkList"
                :context="context"
                @grid-ready="onGridReady"
                @first-data-rendered="onFirstDataRendered"
                enableCellTextSelection="true"
            />
          </div>
          <div class="card-footer">
            <b-button
                variant="success"
                style="display: inline; margin-right: 10px;"
                @click="downExcel"
            >
              엑셀 다운로드
            </b-button>
            <b-button
                v-if="false"
                variant="danger"
                style="display: inline"
                @click="delAllMember"
            >
              수신자 전체삭제
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style>
.ag-header-cell-label {
  justify-content: center;
}

.ag-cell {
  border-right: 1px solid !important;
  border-top: 0 !important;
  border-color: #f4f4f4 !important;
}
</style>
