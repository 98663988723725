<template>
  <div>
    <b-button variant="outline-primary"
              size="sm"
              v-b-modal="`${this.id}`"
    >
      수정
    </b-button>
    <b-modal centered
             :id='this.id'
             title="수신자 정보 수정"
             body-class="p-0 m-0"
             ok-title="수정"
             cancel-title="취소"
             @ok="changeState"
             @cancel="initInfo"
    >
      <div class="modal-body">
        <div class="row mb-3">
          <label class="col-sm-2 col-form-label">
            수신자명
          </label>
          <div class="col-sm-10">
            <input class="form-control" v-model="row.name"/>
          </div>
        </div>
        <div class="row mb-3">
          <label class="col-sm-2 col-form-label">
            연락처
          </label>
          <div class="col-sm-10">
            <input class="form-control" v-model="row.phone"/>
          </div>
        </div>
        <div class="row">
          <label class="col-sm-2 col-form-label">
            수신상태
          </label>
          <div class="col-sm-10">
            <div class="col-form-label">
              <input
                  class="form-check-input"
                  type="radio"
                  name="radiosUsage"
                  id="radiosUsage1"
                  value="Y"
                  style="margin-left: 2px"
                  v-model="row.state"
              />
              <label class="form-check-label" for="radiosUsage1" style="margin-left: 6px">
                허용
              </label>

              <input
                  class="form-check-input"
                  type="radio"
                  name="radiosUsage"
                  id="radiosUsage2"
                  value="N"
                  style="margin-left: 20px"
                  v-model="row.state"
              />
              <label class="form-check-label" for="radiosUsage2" style="margin-left: 6px">
                차단
              </label>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <b-button variant="outline-danger"
              size="sm"
              style="margin-left: 5px;"
              @click="delState"
    >
      삭제
    </b-button>
  </div>
</template>

<script>
import Vue from 'vue'
import axios from "axios";
import {msgConfirmOptCenter, msgModalOptCenter} from "@/components/globalFunctions";

export default Vue.extend({
      name: "editMemberBtn",
      data: function () {
        return {
          row: {},
          id: '',
          tmpRow: {}
        };
      },
      beforeMount() {
        this.row = this.getValueToDisplay(this.params)
        this.tmpRow = {...this.row}
      },
      mounted() {
        this.id = `editInfo${this.row.mk_custom_srl}`
      },
      methods: {
        changeState(me) {
          if (this.row.name === '') {
            this.$bvModal.msgBoxOk('등록하실 수신자 성명을 입력해주세요.', msgModalOptCenter)
            me.preventDefault()
          } else if (this.row.phone === '') {
            this.$bvModal.msgBoxOk('등록하실 수신자 번호를 입력해주세요.', msgModalOptCenter)
            me.preventDefault()
          } else {
            let str = `[${this.row.name}]님의 정보를 변경하시겠습니까? `

            let param = this.row

            this.$bvModal.msgBoxConfirm(str, msgConfirmOptCenter)
                .then((sel) => {
                  if (sel) {
                    axios.put(`/api/kakao/custom/${this.row.mk_custom_srl}`, param)
                        .then((res) => {
                          console.log(res.data)
                          //this.$router.go()
                          this.params.context.componentParent.callMemberList()
                        })
                        .catch((err) => {
                          console.log(err)
                        })
                  } else {
                    this.initInfo()
                  }
                })
          }

        },

        initInfo() {
          this.row = {...this.tmpRow}
          // console.log('row', JSON.stringify(this.row), '/', JSON.stringify(this.tmpRow))
        },


        delState() {
          let str = `[${this.row.name}]님을 수신자 목록에서 제거하시겠습니까? `

          this.$bvModal.msgBoxConfirm(str, msgConfirmOptCenter)
              .then((sel) => {
                if (sel) {
                  axios.delete(`/api/kakao/custom/info/${this.row.mk_custom_srl}`)
                      .then((res) => {
                        console.log(res.data)
                        //this.$router.go()
                        this.params.context.componentParent.callMemberList()
                      })
                      .catch((err) => {
                        console.log(err)
                      })
                }
              })
        },

        getValueToDisplay(params) {
          return params.data
        },
      },
    }
)
</script>

<style scoped>

</style>
