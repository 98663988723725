<template>
  <div>
    <b-button variant="danger"
              size="sm"
              @click="changeState"
              v-if="row.state == 'Y'"
    >
      차단하기
    </b-button>

    <b-button variant="success"
              size="sm"
              @click="changeState"
              v-else
    >
      허용하기
    </b-button>
  </div>
</template>

<script>
import Vue from 'vue'
import axios from "axios";

import {mapState} from "vuex";
import {loggedInfoObject} from "@/state/helpers";
import {msgConfirmOptCenter} from "@/components/globalFunctions";

export default Vue.extend({
      name: "sendBlockBtn",
      data: function () {
        return {
          row: {},
        };
      },
      beforeMount() {
        this.row = this.getValueToDisplay(this.params)
      },
      computed: {
        ...mapState(loggedInfoObject),
      },

      methods: {

        changeState() {
          let str = ''

          if (this.row.state == 'Y') {
            str = `[${this.row.name}]님의 수신상태를 [수신차단]으로 변경하시겠습니까? `
          } else {
            str = `[${this.row.name}]님의 수신상태를 [수신허용]으로 변경하시겠습니까? `
          }

          this.$bvModal.msgBoxConfirm(str, msgConfirmOptCenter)
              .then((sel) => {
                if (sel) {
                  if (this.row.state == 'Y') {
                    this.row.state = 'N'
                  } else {
                    this.row.state = 'Y'
                  }

                  let param = {
                    'mk_custom_srl': this.row.mk_custom_srl,
                    'name': this.row.name,
                    'phone': this.row.phone,
                    'state': this.row.state,
                  }

                  axios.put(`/api/kakao/custom/${this.logged_info.mart_srl}`, param)
                      .then((res) => {
                        console.log(res.data)
                        //this.$router.go()

                        //ag-grid에서 부모 자식간 통신시 사용하는 문장
                        this.params.context.componentParent.callMemberList()
                      })
                      .catch((err) => {
                        console.log(err)
                      })
                }
              })
        },

        getValueToDisplay(params) {
          // console.log('----------------------------------')
          // console.log('data : ',params.data)
          // console.log('value : ', params.value)
          // console.log('formatted', params.valueFormatted)
          // console.log('----------------------------------')
          //return params.valueFormatted ? params.valueFormatted : params.value;
          return params.data
        },
      },
    }
)
</script>

<style scoped>

</style>
